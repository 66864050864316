import React from 'react';

import { AppStateContext } from 'src/contexts/app-state-provider.context';

export const TranslatableText = props => (
  <AppStateContext.Consumer>{({ language }) => props.dictionary[language]}</AppStateContext.Consumer>
);

export const TranslatedBlock = props => (
  <AppStateContext.Consumer>
    {({ language }) => language === props.language && <>{props.children}</>}
  </AppStateContext.Consumer>
);

export const LanguageSwitcher = props => (
  <AppStateContext.Consumer>
    {({ language, toggleLanguage }) => (
      <div className="language-switcher">
        {language === 'english' && (
          <>
            <span className="lang active">ENGLISH</span>
            <span className="separator">|</span>
            <button className="lang" onClick={toggleLanguage}>
              FRANÇAIS
            </button>
          </>
        )}
        {language === 'french' && (
          <>
            <span className="lang active">FRANÇAIS</span>
            <span className="separator">|</span>
            <button className="lang" onClick={toggleLanguage}>
              ENGLISH
            </button>
          </>
        )}
      </div>
    )}
  </AppStateContext.Consumer>
);

export const SmallLanguageSwitcher = props => (
  <AppStateContext.Consumer>
    {({ language, toggleLanguage }) => (
      <button className="language-switcher" onClick={toggleLanguage}>
        {language === 'english' && (
          <>
            <span className="lang">FR</span>
          </>
        )}
        {language === 'french' && (
          <>
            <span className="lang">EN</span>
          </>
        )}
      </button>
    )}
  </AppStateContext.Consumer>
);
