import React, { Component } from 'react';

import './font-size-changer.scss';

const defaultButtonWidth = '20px';
const defaultButtonHeight = '25px';
const defaultButtonsMargin = 0;

const CHANGE_DIRECTION = {
  UP: 'up',
  RESET: 'reset',
  DOWN: 'down',
};

class FontSizeChangerButton extends Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { onClick } = this.props;

    onClick();
  }

  getDefaultButtonContent() {
    const { direction, text } = this.props;
    const size = direction === CHANGE_DIRECTION.UP ? '1.2em' : direction === CHANGE_DIRECTION.DOWN ? '0.8em' : '1em';

    return <span style={{ fontSize: size }}>{text}</span>;
  }

  render() {
    const { direction } = this.props;
    const { style = {}, customButton = this.getDefaultButtonContent() } = this.props;

    return (
      <button className={`font-size-${direction}`} style={style} onClick={this.onClick}>
        {customButton}
      </button>
    );
  }
}

class FontSizeChanger extends Component {
  constructor() {
    super();
    this.setChangeDirection.bind(this);
    this.state = {
      options: {
        stepSize: 2,
        range: 3,
      },
      changeCount: 0,
      changeDirection: null,
    };
  }

  componentDidMount() {
    const { options } = this.props;

    this.setState({
      options: Object.assign({}, this.state.options, options),
    });
  }

  getFontSizeDetails(fontSize) {
    const value = parseFloat(fontSize);
    const unit = fontSize.toString().replace(value.toString(), '');

    return {
      value,
      unit,
    };
  }

  applyFontSize(el) {
    const { stepSize } = this.state.options;
    const { changeDirection } = this.state;
    const change = changeDirection === CHANGE_DIRECTION.UP ? stepSize : stepSize * -1;
    const style = window.getComputedStyle(el, null).getPropertyValue('font-size');
    const { value } = this.getFontSizeDetails(style);
    const newFontSize = value + change;

    el.style.fontSize = newFontSize + 'px';
  }

  iterateChildren(element) {
    this.applyFontSize(element);
    if (!element || !element.children || element.children.length === 0) return;

    for (let child of element.children) {
      this.iterateChildren(child);
    }
  }

  updateChangeCount() {
    const { changeCount, changeDirection } = this.state;
    const change = changeDirection === CHANGE_DIRECTION.UP ? 1 : -1;

    this.setState(
      {
        changeCount: changeCount + change,
      },
      () => this.saveChangeCount(),
    );
  }

  saveChangeCount() {
    localStorage.setItem('FontSizeChangerChangeCount', JSON.stringify(this.state.changeCount));
  }

  iterateElementsAndApplyFontSizeChange() {
    const { targets } = this.props;

    for (let target of targets) {
      const elements = document.querySelectorAll(target);

      for (let element of elements) {
        this.iterateChildren(element);
      }
    }

    this.updateChangeCount();
  }

  validateChangePerRange() {
    const { changeCount, changeDirection } = this.state;
    const { range } = this.state.options;
    let isValid = true;

    if (changeDirection === CHANGE_DIRECTION.UP && changeCount >= range) {
      isValid = false;
    }
    if (changeDirection === CHANGE_DIRECTION.DOWN && changeCount <= range * -1) {
      isValid = false;
    }

    return isValid;
  }

  initFontSizeChange() {
    this.validateChangePerRange() && this.iterateElementsAndApplyFontSizeChange();
  }

  setChangeDirection(up, cb) {
    const changeDirection = up ? CHANGE_DIRECTION.UP : CHANGE_DIRECTION.DOWN;

    this.setState(
      {
        changeDirection: changeDirection,
      },
      cb,
    );
  }

  onFontSizeUp() {
    this.setChangeDirection(true, this.initFontSizeChange);
  }

  onFontSizeReset() {
    const changeCount = this.state.changeCount;

    if (changeCount !== 0) {
      const stepSize = this.state.options.stepSize;
      this.setState({
        options: {
          stepSize: Math.abs(changeCount * stepSize),
          range: this.state.options.range,
        },
      });

      this.setState(
        {
          changeDirection: changeCount > 0 ? CHANGE_DIRECTION.DOWN : CHANGE_DIRECTION.UP,
        },
        () => {
          this.initFontSizeChange();
          this.setState(
            {
              options: {
                stepSize: stepSize,
                range: this.state.options.range,
              },
              changeCount: 0,
            },
            () => this.saveChangeCount(),
          );
        },
      );
    }
  }

  onFontSizeInit() {
    if (this.didInit) return;
    this.didInit = true;
    var changeCount = 0;
    try {
      changeCount = JSON.parse(localStorage.getItem('FontSizeChangerChangeCount'));
    } catch (e) {}

    if (changeCount !== 0) {
      const stepSize = this.state.options.stepSize;
      this.setState({
        options: {
          stepSize: Math.abs(changeCount * stepSize),
          range: this.state.options.range,
        },
      });

      this.setState(
        {
          changeDirection: changeCount > 0 ? CHANGE_DIRECTION.UP : CHANGE_DIRECTION.DOWN,
        },
        () => {
          this.initFontSizeChange();
          this.setState(
            {
              options: {
                stepSize: stepSize,
                range: this.state.options.range,
              },
              changeCount: changeCount,
            },
            () => {
              this.saveChangeCount();
            },
          );
        },
      );
    }
  }

  onFontSizeDown() {
    this.setChangeDirection(false, this.initFontSizeChange);
  }

  getFontSizeChangerStyle(customButtons) {
    const { style = {}, verticalAlignment = false, buttonsMargin = defaultButtonsMargin } = customButtons;

    const buttonWidth = this.getFontSizeDetails(style.width || defaultButtonWidth);
    const buttonHeight = this.getFontSizeDetails(style.height || defaultButtonHeight);
    const containerWidth =
      (verticalAlignment === false ? buttonWidth.value * 3 + buttonsMargin : buttonWidth.value) + buttonWidth.unit;

    const containerHeight =
      (verticalAlignment === false ? buttonHeight.value : buttonHeight.value * 3 + buttonsMargin) + buttonHeight.unit;

    return {
      width: containerWidth,
      height: containerHeight,
    };
  }

  getFontSizeChangerButtonStyle(direction, customButtons) {
    const {
      style = {},
      verticalAlignment = false,
      reverseOrder = false,
      buttonsMargin = defaultButtonsMargin,
    } = customButtons;
    const buttonWidth = this.getFontSizeDetails(style.width || defaultButtonWidth);
    const buttonHeight = this.getFontSizeDetails(style.height || defaultButtonHeight);
    const buttonStyle = {
      width: buttonWidth.value + buttonWidth.unit,
      height: buttonHeight.value + buttonHeight.unit,
    };

    const marginUnit = verticalAlignment === false ? buttonWidth.unit : buttonHeight.unit;
    const margin = buttonsMargin + marginUnit;

    if (
      (direction === CHANGE_DIRECTION.UP && reverseOrder === false) ||
      (direction === CHANGE_DIRECTION.DOWN && reverseOrder === true)
    ) {
      if (verticalAlignment === false) {
        buttonStyle.marginRight = margin;
      } else {
        buttonStyle.marginBottom = margin;
      }
    }

    return Object.assign({}, style, buttonStyle);
  }

  render() {
    const { customButtons = {} } = this.props;
    const { up, down } = customButtons;

    const fontSizeChangerStyle = this.getFontSizeChangerStyle(customButtons);
    const fontSizeChangerUpButtonStyle = this.getFontSizeChangerButtonStyle(CHANGE_DIRECTION.UP, customButtons);
    const fontSizeChangerResetButtonStyle = this.getFontSizeChangerButtonStyle(CHANGE_DIRECTION.RESET, customButtons);
    const fontSizeChangerDownButtonStyle = this.getFontSizeChangerButtonStyle(CHANGE_DIRECTION.DOWN, customButtons);

    return (
      <div className="font-size-changer" style={fontSizeChangerStyle}>
        <div className="font-size-changer-buttons" style={fontSizeChangerStyle}>
          <FontSizeChangerButton
            direction={CHANGE_DIRECTION.DOWN}
            text={'A-'}
            customButton={down}
            onClick={this.onFontSizeDown.bind(this)}
            style={fontSizeChangerDownButtonStyle}
          />
          <FontSizeChangerButton
            direction={CHANGE_DIRECTION.RESET}
            text={'A'}
            customButton={down}
            onClick={this.onFontSizeReset.bind(this)}
            style={fontSizeChangerResetButtonStyle}
          />
          <FontSizeChangerButton
            direction={CHANGE_DIRECTION.UP}
            text={'A+'}
            customButton={up}
            onClick={this.onFontSizeUp.bind(this)}
            style={fontSizeChangerUpButtonStyle}
          />
        </div>
      </div>
    );
  }
}

export default FontSizeChanger;
